<template>
    <div class="flexPage darkPage">
        <navbar :params="pageInfo">
            <template #right>
                <span @click="save" style="color: #323233">保存</span>
            </template>
        </navbar>
        <span class="tips">拍摄上传您的二代身份证照片</span>
        <!-- 身份证正面 -->
        <van-uploader class="mt30" name="file" multiple :after-read="onAfterReadFront">
            <div class="icon_id_face">
                <template v-if="formData.idCardFront">
                    <img :src="formData.idCardFront" alt="">
                </template>
                <template v-else>
                    <img src="../../assets/img/icon_id_face.png" alt="">
                </template>
            </div>
        </van-uploader>
        <!-- 身份证反面 -->
        <van-uploader class="mt80" name="file" multiple :after-read="onAfterReadBack">
            <div class="icon_id_face">
                <template v-if="formData.idCardBack">
                    <img :src="formData.idCardBack" alt="">
                </template>
                <template v-else>
                    <img src="../../assets/img/icon_id_back.png" alt="">
                </template>
            </div>
        </van-uploader>
    </div>
</template>

<script>
import navbar from '@/views/modules/navbar.vue';
import store from '../user/store/common';

export default {
    data() {
        return {
            pageInfo: {
                title: '',
                border: false,
                customTitle: this.$t('assets.personal.identity_authentication'),
                navbarStyle: 'normal',
                backname: "real",
            },
            formData: {
                idCardFront: '',
                idCardBack: ''
            }
        }
    },
    // mounted() {
    //     this.formData = store.state.card;
    // },
    components: {
        navbar
    },
    methods: {
        onAfterReadFront(file) {
            const formData = new FormData();
            formData.append('file', file.file);
            this.$http.post(this.host + '/uc/upload/local/image', formData).then((response) => {
                var resp = response.body;
                if (resp.code == 0) {
                    this.formData.idCardFront = resp.data;
                } else {
                    this.$toast(resp.message);
                }
            });
        },
        onAfterReadBack(file) {
            const formData = new FormData();
            formData.append('file', file.file);
            this.$http.post(this.host + '/uc/upload/local/image', formData).then((response) => {
                var resp = response.body;
                if (resp.code == 0) {
                    this.formData.idCardBack = resp.data;
                } else {
                    this.$toast(resp.message);
                }
            });
        },
        save() {
            if (!this.formData.idCardFront || !this.formData.idCardBack) return this.$toast('请确认是否已经上传全部图片');
            store.commit('setCard', { ...this.formData, ...store.state.card });
            this.$mts.goto('real');
        }
    }
}
</script>

<style>
.mt30 {
    margin-top: 7.6336vw;
}

.mt80 {
    margin-top: 20.3562vw;
}

.tips {
    margin-top: 3.8168vw;
    text-align: center;
    font-size: 3.5623vw;
    color: #969799;
}

.icon_id_face {
    min-width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.icon_id_face img {
    width: 68.7023vw;
    height: 42.7481vw;
    object-fit: contain;
}
</style>